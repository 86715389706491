import { Input, message, Popover, Select, Tag } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis/make-api-call";
import { GlobalContext } from "../../../commonContext";
import EsfTable from "../../../component/esf-table";
import Pagination from "../../../component/esf-table/lib/pagination";
import NoDataTable from "../../../component/no-data-table";
import {
  ConvertParams,
  DateFormat,
  DefaultPerPage,
  DisplayNotification,
  DownloadFileUsingApi,
  ENDPOINT,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  NumberWithCommas,
} from "../../../config";
import RangePickerESF from "../../../component/range-picker";
import dayjs from "dayjs";
import CampaignModal from "./lib/campaign-modal";
import CardHeader from "../../../component/card-header";

const ProductDetails = () => {
  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [list, set_list] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");
  const [selectedCamp, setSelectedCamp] = useState([]);
  const [campaignView, setCampaignView] = useState(false);

  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    search: "",
    marketplace_id: null,
    start_date: dayjs().add(-90, "d"),
    end_date: dayjs(),
  });

  useEffect(() => {
    localStorage.setItem("token_type", "");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetMarketplace();
      } else {
        setLoading(false);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetMarketplace();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetProductAnalyticsList({
        ...all_filters,
        start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
    } else {
      setLoading(false);
    }
  };

  const GetProductAnalyticsList = async (filters) => {
    setLoading(true);
    const response = await MakeApiCall(
      `product-analytics/get-product-list${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_list(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
      start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
    };
    GetProductAnalyticsList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
      start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
    };
    GetProductAnalyticsList(apiObj);
  };

  const columns = [
    {
      title: "#",
      width: 20,
      fixed: "left",
      render: (text) => (
        <span>
          {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + text?.key}
        </span>
      ),
    },

    {
      title: "Product",
      dataIndex: "item_name",
      key: "item_name",
      fixed: "left",
      render: (text, record) => (
        <div className="d-flex align-items-center border border-dashed border-gray-300 p-3 rounded bdr-left-user1">
          <div className="symbol symbol-60px overflow-hidden me-3 shadow-sm">
            <img src={record?.image_url} />
          </div>
          <div className="d-flex flex-column">
            <Popover
              content={
                <div
                  style={{
                    height: "max-content",
                    maxWidth: "400px",
                  }}
                >
                  {record?.item_name}
                </div>
              }
              placement="bottom"
            >
              <span
                className="text-dark mb-1 fw-bolder popoverActionIconSingleLine fs-6"
                style={{ width: "300px" }}
              >
                {record?.item_name}
              </span>
            </Popover>

            <div>
              <span
                className="fs-7 cursor-pointer"
                // onClick={() => {
                //   window.open(`https://www.amazon.com/dp/${record?.asin1}`);
                // }}
              >
                <b>ASIN:</b>{" "}
                <span className="text-primary">{record?.asin1}</span>
              </span>{" "}
              |
              <span className="fs-7">
                <b>SKU:</b>{" "}
                <span className="text-primary">{record?.seller_sku}</span>
              </span>
            </div>
          </div>
        </div>
      ),
      width: 300,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          className="fw-bold"
          color={
            status === "Active"
              ? "success"
              : status === "Inactive"
              ? "error"
              : "orange"
          }
        >
          {status}
        </Tag>
      ),
      width: 125,
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      width: 180,
    },
    {
      title: "Fulfillment Channel",
      dataIndex: "fulfillment_channel",
      key: "fulfillment_channel",
      width: 120,
      render: (text) => {
        return (
          <Tag
            className="fw-bold"
            color={text === "DEFAULT" ? "orange" : "purple"}
          >
            {text === "DEFAULT" ? "FBM" : "FBA"}
          </Tag>
        );
      },
    },
    {
      title: "Product Id",
      dataIndex: "product_id",
      key: "product_id",
      width: 120,
    },
    {
      title: "Sales Price",
      dataIndex: "price",
      key: "price",
      width: 100,
      render: (price) => (
        <div>
          <span className="fw-bold">
            {selected_currency_sign}
            {NumberWithCommas(price)}
          </span>
        </div>
      ),
    },
    {
      title: "Stock Quantity",
      dataIndex: "stock_quantity",
      key: "stock_quantity",
      width: 120,
      render: (text) => (
        <div>
          <span className="fw-bold">{text}</span>
        </div>
      ),
    },
    {
      title: "Sales Rank",
      dataIndex: "sales_rank",
      key: "sales_rank",
      width: 80,
      //   sorter: (a, b) => a.salesRank - b.salesRank, // Optional: sorting by sales rank
    },
    // {
    //   title: "Organic Sales",
    //   dataIndex: "organicSales",
    //   key: "organicSales",
    //   width: 120,
    //   //   render: (text) => `$${text.toLocaleString()}`, // Format as currency
    // },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      width: 120,
      render: (data) => (
        <div>
          <span className="">
            {selected_currency_sign}
            {NumberWithCommas(data)}
          </span>
        </div>
      ),
      //   render: (text) => `$${text.toLocaleString()}`,
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: 120,
    },
    {
      title: "Impression",
      dataIndex: "impressions",
      key: "impressions",
      width: 120,
    },
    {
      title: "Ads Unit Sold",
      dataIndex: "ads_unit_sold",
      key: "ads_unit_sold",
      width: 120,
    },
    {
      title: "Ads sales ",
      dataIndex: "ads_sales",
      key: "ads_sales",
      width: 120,
      render: (data) => (
        <div>
          <span className="">
            {selected_currency_sign}
            {NumberWithCommas(data)}
          </span>
        </div>
      ),
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      width: 120,
      render: (text) => {
        return <span> {text || "0"}</span>;
      },
      //   render: (text) => `$${text.toFixed(2)}`, // Format CPC to two decimal places
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      width: 120,
      render: (text) => {
        return <span>{text || "0"}%</span>;
      },
      //   render: (text) => `${text}%`, // Format as percentage
    },
    {
      title: "ACoS",
      dataIndex: "ACoS",
      key: "ACoS",
      width: 120,
      render: (data) => (
        <div>
          <span className="">{NumberWithCommas(data)}%</span>
        </div>
      ),
      //   render: (text) => `$${text.toLocaleString()}`,
    },
    {
      title: "Unit Sold",
      dataIndex: "units_sold",
      key: "units_sold",
      width: 120,
      render: (data) => (
        <div>
          <span className="">{NumberWithCommas(data)}</span>
        </div>
      ),
    },
    {
      title: "Organic Sales",
      dataIndex: "organic_sales",
      key: "organic_sales",
      width: 120,
      render: (data) => (
        <div>
          <span className="">
            {selected_currency_sign}
            {NumberWithCommas(data)}
          </span>
        </div>
      ),
    },
    {
      title: "TACoS",
      dataIndex: "TACoS",
      key: "TACoS",
      width: 120,
      render: (data) => (
        <div>
          <span className="">{NumberWithCommas(data)}%</span>
        </div>
      ),
    },
    // {
    //   title: "Ratings",
    //   dataIndex: "ratings",
    //   key: "ratings",
    // },
    // {
    //   title: "Reviews",
    //   dataIndex: "reviews",
    //   key: "reviews",
    // },

    {
      title: "Campaigns",
      width: 300,
      render: (text) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="two-line">
              {text?.campaign_name?.[0]?.campaign_name || "-"}
            </div>
            {text?.campaign_name?.length > 0 && (
              <div>
                {" "}
                <span
                  className="ms-2 cursor-pointer d-flex"
                  onClick={() => {
                    setSelectedCamp(text?.campaign_name || []);
                    setCampaignView(true);
                  }}
                >
                  <i className="ki-outline ki-eye fs-3 text-dark" />
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Marketplace Id",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      width: 120,
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
      width: 120,
    },

    {
      title: "Description",
      dataIndex: "item_description",
      key: "item_description",
      width: 150,
      render: (text) => {
        return !text ? (
          "-"
        ) : (
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  maxWidth: "600px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
          >
            <span
              className="text-dark mb-1 fw-bolder popoverActionIconSingleLine fs-6"
              style={{ width: "150px" }}
            >
              {text || "-"}
            </span>
          </Popover>
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => (
        <span>
          {moment(new Date(created_at * 1000)).format("MMM DD, YYYY hh:mm A")}
          <br />
          <small className="text-gray-500">
            ({moment(created_at * 1000).fromNow()})
          </small>
        </span>
      ),
      width: 180,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      render: (updated_at) => (
        <span>
          {moment(new Date(updated_at * 1000)).format("MMM DD, YYYY hh:mm A")}
          <br />
          <small className="text-gray-500">
            ({moment(updated_at * 1000).fromNow()})
          </small>
        </span>
      ),
      width: 180,
    },
  ];

  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        <div className="row">
          <div className="col-12 px-3">
            <CardHeader>
              <div className="d-flex align-items-center justify-content-between py-4 ">
                <label className=" align-items-start flex-column me-3">
                  <span className="fw-bold text-dark fs-2 ms-3">
                    Product Analytics
                  </span>
                </label>
                <div className="card-toolbar me-3">
                  <div>
                    <div className="d-flex flex-wrap gap-4 align-items-center">
                      <div className="position-relative">
                        <Input
                          prefix={
                            <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                          }
                          placeholder="Search by ASIN or SKU"
                          size="large"
                          value={all_filters?.search}
                          onChange={(e) => {
                            set_all_filters({
                              ...all_filters,
                              search: e.target.value,
                            });
                          }}
                          onPressEnter={() => {
                            const apiObj = {
                              ...all_filters,
                              page: 1,
                              "per-page": DefaultPerPage,
                              start_date: moment(
                                all_filters?.start_date?.$d
                              ).format("YYYY-MM-DD"),
                              end_date: moment(
                                all_filters?.end_date?.$d
                              ).format("YYYY-MM-DD"),
                            };
                            setLoading(true);
                            GetProductAnalyticsList(apiObj);
                          }}
                        />
                      </div>
                      <div className="position-relative ">
                        <RangePickerESF
                          className="ant_common_input"
                          id="aalacart_date_picker"
                          size="large"
                          allowClear={false}
                          style={{ width: "290px" }}
                          format={DateFormat}
                          value={[
                            all_filters?.start_date,
                            all_filters?.end_date,
                          ]}
                          // getPopupContainer={(triggerNode) =>
                          //   triggerNode.parentNode
                          // }
                          getPopupContainer={() =>
                            document.getElementById("kt_content_container")
                          }
                          onChange={(e) => {
                            const filters_ = all_filters;
                            filters_.start_date = e?.[0];
                            filters_.end_date = e?.[1];
                            set_all_filters({ ...filters_ });

                            const apiObj = {
                              ...all_filters,
                              page: 1,
                              "per-page": DefaultPerPage,
                              start_date: moment(e?.[0]?.$d).format(
                                "YYYY-MM-DD"
                              ),
                              end_date: moment(e?.[1]?.$d).format("YYYY-MM-DD"),
                            };
                            setLoading(true);
                            GetProductAnalyticsList(apiObj);
                          }}
                        />
                      </div>
                      <div className="position-relative ">
                        <Select
                          className="w-200px"
                          size="large"
                          placeholder="Select Marketplace"
                          options={GetMarketplaceList(marketplace_list)}
                          value={selected_marketplace}
                          onChange={(e) => {
                            set_selected_marketplace(e);
                            set_selected_currency_sign(currency_list[e]);
                            const apiObjFilter = {
                              ...all_filters,
                              marketplace_id: e,
                            };
                            set_all_filters(apiObjFilter);

                            const apiObj = {
                              ...apiObjFilter,
                              page: 1,
                              "per-page": DefaultPerPage,
                              start_date: moment(
                                apiObjFilter?.start_date?.$d
                              ).format("YYYY-MM-DD"),
                              end_date: moment(
                                apiObjFilter?.end_date?.$d
                              ).format("YYYY-MM-DD"),
                            };
                            setLoading(true);
                            GetProductAnalyticsList(apiObj);
                          }}
                        />
                      </div>
                      <div className="position-relative ">
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            const data = {
                              start_date: moment(
                                all_filters?.start_date?.$d
                              ).format("YYYY-MM-DD"),
                              end_date: moment(
                                all_filters?.end_date?.$d
                              ).format("YYYY-MM-DD"),
                              marketplace_id: selected_marketplace,
                            };
                            message.loading("Loading...", 0);
                            DownloadFileUsingApi(
                              `${ENDPOINT}product-analytics/export-product${ConvertParams(
                                data
                              )}`,
                              "product_analytics"
                            );
                          }}
                        >
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardHeader>
          </div>
        </div>

        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-shadow card-xxl-stretch mb-5 mb-xl-8">
              <div className="separator separator-dashed"></div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body py-2">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {loading ? (
                    <NoDataTable columns={columns} type="loading" />
                  ) : list?.length > 0 ? (
                    <EsfTable
                      columns={columns?.map((d) => ({
                        ...d,
                        // width: d?.title?.length * 13,
                      }))}
                      sticky={{
                        offsetHeader: "10px",
                      }}
                      scroll={{
                        x: "max-content",
                        y: "calc(100vh - 300px)",
                      }}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                    />
                  ) : (
                    <>
                      <NoDataTable columns={columns} type="no-data" />
                    </>
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={all_filters?.["per-page"]}
                  page={all_filters?.page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>

      {campaignView && (
        <CampaignModal
          selectedCamp={selectedCamp}
          show={campaignView}
          onHide={() => setCampaignView(false)}
        />
      )}
    </>
  );
};

export default ProductDetails;
