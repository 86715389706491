import React from 'react';
import Chart from 'react-apexcharts';
import { Card, Select, Avatar, Space, Typography, Button, Radio} from 'antd';
import RangePickerESF from '../../../../../component/range-picker';

const { Title, Text } = Typography;
const { Option } = Select;

function PerformanceChart() {

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };


  const options = {
    series: [{
      name: 'Inbound Shipments',
      type: 'column',
      data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
    }, {
      name: 'Lost Removal',
      type: 'line',
      data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
    }, {
      name: 'PPC Unit Sold',
      type: 'line',
      data: [15, 24, 30, 35, 40, 28, 36, 48, 52, 60, 65, 70]
    }, {
      name: 'Unit Sold',
      type: 'line',
      data: [100, 120, 140, 160, 180, 200, 230, 250, 270, 300, 320, 350]
    }],
    chart: {
      height: 350,
      type: 'line',
      toolbar: { show: false },
    },
    markers: {
      size: 5,
      hover: {
        size: 9
      }
    },
    colors: ["#3792F0", "#333", "#B56263", '#d7cc6c', '#1f4173'],
    plotOptions: {
      bar: { horizontal: false, columnWidth: "30%", borderRadius: 3 },
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: [0, 3.5, 3.5, 3.5],
      dashArray: [0, 4, 0, 0],
      lineCap: "round",
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1, 2, 3]
    },
    grid: {
      strokeDashArray: 3,
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
    },
    labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
    xaxis: {
      type: 'datetime'
    },
    yaxis: [{
      title: {
        text: 'Inbound Shipments',
        style: { colors: '#333', fontSize: "14px" }
      },
      labels: {
        formatter: function (value) {
          return value + "k";
        },
        offsetX: -12,
        offsetY: 0,
      },
    }, {
      opposite: true,
      title: {
        text: 'Lost Removal',
        style: { colors: '#333', fontSize: "14px" }
      },
    }, {
      opposite: true,
      title: {
        text: 'PPC Unit Sold & Unit Sold',
        style: { colors: '#333', fontSize: "14px" }
      },
      labels: {
        formatter: function (value) {
          return value;
        },
        offsetX: 12,
        offsetY: 0,
      },
    }]
  };


  // Sample data
  const products = [
    {
      id: '1',
      name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      sku: 'SKU00001',
      image: 'https://via.placeholder.com/40', // replace with actual image URLs
    },
    {
      id: '2',
      name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      sku: 'SKU00002',
      image: 'https://via.placeholder.com/40',
    },
    {
      id: '3',
      name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      sku: 'SKU00003',
      image: 'https://via.placeholder.com/40',
    },
  ];

  return (
    <>
        <Card title="Business Performance Graph" extra={
           <Space >
           {/* <Select className='bdr' {...sharedProps} options={Options}/> */}
           <Select
               showSearch
               placeholder="Custom KPI"
               optionFilterProp="label"
               variant='filled'
               size='large'
               onChange={onChange}
               onSearch={onSearch}
               defaultValue={['ppc sales','products','unit sold']}
               className='min-w-200px'
               mode='multiple'
               maxTagCount='responsive'
               options={[
               {
                   value: 'ppc sales',
                   label: 'PPC Sales',
               },
               {
                   value: 'products',
                   label: 'Products',
               },
               {
                   value: 'unit sold',
                   label: 'Unit Sold',
               },
               ]}
           />
           <Select
           placeholder="Select a product"
           style={{ width: '100%' }}
           variant='filled'
           size='large'
           optionLabelProp="label"
           className='min-w-250px'
            mode='multiple'
               maxTagCount='responsive'
         >
           {products.map((product) => (
             <Option 
               key={product.id} 
               value={product.id} 
               label={product.name} 
               style={{ display: 'flex', alignItems: 'center' }}
             >
               <div style={{ display: 'flex', alignItems: 'center' }}>
                 <Avatar src={product.image} size={40} className='d-block' style={{ marginRight: 8, minWidth:'40px' }} />
                 <div>
                   <Text className='text-one' strong>{product.name}</Text>
                   <Text type="secondary">SKU: {product.sku}</Text>
                 </div>
               </div>
             </Option>
           ))}
         </Select>
           <RangePickerESF variant='filled' className="w-225px"/>
           <Radio.Group
           size='large'
           className='fs-7'
      block
      options={[
        {
          label: 'Sales Analysis',
          value: 'sales-analysis',
        },
        {
          label: 'Refunds Analysis',
          value: 'refunds-analysis',
        },
      ]}
      defaultValue="sales-analysis"
      optionType="button"
      buttonStyle="solid"
    />
       </Space>
        }>
        <Chart options={options} series={options.series} type="line" height={350} />
        {/* <Chart options={chartOptions} series={chartOptions.series} type="line" height={350} /> */}
        </Card>
    </>
  )
}

export default PerformanceChart