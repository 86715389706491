import styled from "styled-components";

export const Wrapper = styled.div`

    .ant-card .ant-card-head {
    min-height: 70px;
}
input::placeholder, .ant-select .ant-select-selection-placeholder {
    color: #919191 !important;
}
.ant-segmented .ant-segmented-item-label {
    font-weight: 500;
    font-family: Inter;
    padding: 4px 11px;
    font-size: 15px;
}
.ant-segmented {
    background: rgb(243 243 243);
    height: 40px;
}
    button.ant-btn span {
    font-size: 14px;
    line-height: 0;
    display: flex;
}
    .ant-table-wrapper .ant-table-cell, .ant-table-wrapper .ant-table-thead>tr>th, .ant-table-wrapper .ant-table-tbody>tr>th, .ant-table-wrapper .ant-table-tbody>tr>td, .ant-table-wrapper tfoot>tr>th, .ant-table-wrapper tfoot>tr>td {
    position: relative;
    padding: 20px 16px;
    overflow-wrap: break-word;
}

.text-two{
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    word-break: break-all;
    height: 47px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 200px;
}
.cus-btn span {
    font-size: 15px !important;
}
.cus-btn svg {
    width: 24px;
    height: 24px;
    margin-left: 10px;
}

.cus-btn {
    border: 1px solid red;
    display: flex;
    align-items: center;
        color: #3794f5;
    background: #ffffff00;
    box-shadow: 0px 7px 8px rgb(5 172 255 / 11%);
    border: 3px solid #2196F3 !important;
    padding: 20px 20px;
}
.cus-card .ant-card .ant-card-body {
    padding: 14px 24px;
    border-radius: 0 0 8px 8px;
}   
.ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
}
.listing .r {
    background-color: #bd000017;
}
.listing .w {
    background-color: #e996002e;
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
}
.listing .w .text-warning{
   color: #bb9200 !important;
       
}   
.listing .g {
    background-color: #06c51d2b;
}
.border-primary{border-color: #3699ff !important;}





.cus-card .ant-card .ant-card-body {
    padding: 14px 24px;
    border-radius: 0 0 8px 8px;
    position: relative;
    z-index: 1;
    background-color: #ffffffc7;
    backdrop-filter: blur(21px);
}
.prod-p-card{overflow: hidden;}
.cus-card .prod-p-card::before {
    background-color: #3989dc;
    /* border: 1px solid; */
    content: '';
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: block;
    position: absolute;
    z-index: 1;
}
    .cus-card .prod-p-card::after {
    background-color: #ffb37d;
    /* border: 1px solid; */
    content: '';
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: block;
    position: absolute;
        z-index: 0;
    bottom: 0;
    right: 0;
}
    .cus-card .prod-p-card{position:relative}
    .cus-table table tr:last-child {
    border: 1px solid !important;
    background: #fafafa;
    position: sticky;
    bottom: 0;
}
`;
