import { Checkbox, Popover, Select, Tag } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../apis/make-api-call";
import { GlobalContext } from "../../../../../commonContext";
import EsfTable from "../../../../../component/esf-table";
import Pagination from "../../../../../component/esf-table/lib/pagination";
import NoDataTable from "../../../../../component/no-data-table";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
  GetMarketplaceDropdown,
  GetMarketplaceList,
} from "../../../../../config";
import UpdateSchedulerModal from "./lib/update-scheduler-modal";

const AdminDataSchedulerLog = () => {
  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [list, set_list] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [event_list, set_event_list] = useState({});
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    search: "",
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllRows, setIsAllRows] = useState(false);
  const [user_list, set_user_list] = useState([]);
  const [isOpenUdpateModal, setIsOpenUdpateModal] = useState(false);

  // const [selected_marketplace, set_selected_marketplace] = useState(null);
  // const [marketplace_list, set_marketplace_list] = useState([]);
  // const [currency_list, set_currency_list] = useState({});
  // const [selected_currency_sign, set_selected_currency_sign] = useState("");

  useEffect(() => {
    GetDataSchedulerLogList({
      ...all_filters,
    });
    GetDataSchedulerEventNameList();
    GetAllUsersList();
    // localStorage.setItem("token_type", "");
    // if (contextValue?.data?.user_type == 2) {
    //   if (localStorage.getItem("sub_user_list")) {
    //     GetMarketplace();
    //   } else {
    //     setLoading(false);
    //     DisplayNotification("error", "Error", "User not found");
    //   }
    // } else {
    //   GetMarketplace();
    // }
  }, [contextValue?.data?.selected_sub_user]);

  const GetDataSchedulerLogList = async (filters) => {
    setLoading(true);
    const response = await MakeApiCall(
      `admin-data-scheduler${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_list(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetDataSchedulerEventNameList = async () => {
    setLoading(true);
    const response = await MakeApiCall(
      `admin-data-scheduler-event-list`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      // const newData = Object.entries(response?.data)?.map((d) => {
      //   return d?.[0];
      // });
      set_event_list(response?.data);
    } else {
      set_event_list([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetAllUsersList = async () => {
    const response = await MakeApiCall(`user/get-all-user`, `get`, null, true);
    if (response?.status) {
      set_user_list(response?.data);
    } else {
      set_user_list([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
    };
    GetDataSchedulerLogList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
    };
    GetDataSchedulerLogList(apiObj);
  };

  const columns = [
    {
      title: () => {
        return (
          <Checkbox
            checked={isAllRows}
            size="large"
            onChange={(e) => {
              if (e.target.checked) {
                const allRows = list?.map((d) => d?.id);
                setSelectedRows(allRows);
              } else {
                setSelectedRows([]);
              }
              setIsAllRows(e.target.checked);
            }}
          />
        );
      },
      render: (text) => (
        <Checkbox
          checked={
            selectedRows.filter((a) => a == text?.id)?.length == 1
              ? true
              : false
          }
          size="large"
          onChange={(e) => {
            const checkboxId = text?.id;
            setSelectedRows((prev) => {
              if (e?.target?.checked) {
                return [...prev, checkboxId];
              } else {
                return prev.filter((id) => id !== checkboxId);
              }
            });
          }}
        />
      ),
    },
    {
      title: "#",
      render: (text) => (
        <span>
          {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + text?.key}
        </span>
      ),
    },
    {
      title: "Event Process Type",
      dataIndex: "event_process_type",
      key: "event_process_type",
      width: 150,
      render: (text) => <span className="fw-bold">{text}</span>,
    },
    {
      title: "Event Type",
      dataIndex: "event_type",
      key: "event_type",
      width: 150,
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      key: "event_name",
      width: 300,
      render: (text, record) => (
        <Popover
          content={
            <div style={{ height: "max-content", maxWidth: "400px" }}>
              {record?.event_name}
            </div>
          }
          placement="bottomLeft"
        >
          <div className="mb-1 text-one fs-6 popoverActionIconSingleLine">
            <span className="fw-bolder">{record?.event_name}</span>
          </div>
        </Popover>
      ),
    },
    {
      title: "Event Label",
      dataIndex: "event_label",
      key: "event_label",
      width: 200,
    },
    {
      title: "Previous Days",
      dataIndex: "previous_days",
      key: "previous_days",
      width: 100,
    },
    {
      title: "Previous Status",
      dataIndex: "previous_status",
      key: "previous_status",
      width: 100,
      render: (text) => (
        <Tag
          color={text === "DONE" ? "#389e0d" : "#c69d2d"}
          className="fw-bold"
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Update Daily Days",
      dataIndex: "update_daily_days",
      key: "update_daily_days",
      width: 150,
    },
    {
      title: "Update Daily Status",
      dataIndex: "update_daily_status",
      key: "update_daily_status",
      width: 150,
      render: (text) => (
        <Tag
          color={text === "DONE" ? "#389e0d" : "#c69d2d"}
          className="fw-bold"
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Request Delay",
      dataIndex: "request_delay",
      key: "request_delay",
      width: 150,
    },
    {
      title: "GroupBy Days",
      dataIndex: "groupby_days",
      key: "groupby_days",
      width: 150,
    },
    {
      title: "Current Day Delay Frequency",
      dataIndex: "current_day_delay_hr_frequency",
      key: "current_day_delay_hr_frequency",
      width: 150,
    },
    {
      title: "Current Day Frequency",
      dataIndex: "current_day_hr_frequency",
      key: "current_day_hr_frequency",
      width: 150,
    },
    {
      title: "Last Execution Time",
      dataIndex: "last_execution_time",
      key: "last_execution_time",
      width: 150,
      render: (last_execution_time) => {
        return last_execution_time ? (
          <>
            <span>
              {moment(new Date(last_execution_time * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
              <br />
              <small className="text-gray-500">
                ({moment(last_execution_time * 1000).fromNow()})
              </small>
            </span>
          </>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (text) => (
        <Tag color={text === 1 ? "green" : "red"} className="fw-bold">
          {text === 1 ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    // {
    //   title: "Marketplace",
    //   dataIndex: "marketplace",
    //   key: "marketplace",
    //   width: 150,
    // },
    // {
    //   title: "Marketplace ID",
    //   dataIndex: "marketplace_id",
    //   key: "marketplace_id",
    //   width: 100,
    // },
    // { title: "Region", dataIndex: "region", key: "region", width: 120 },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => (
        <span>
          {moment(new Date(created_at * 1000)).format("MMM DD, YYYY hh:mm A")}
          <br />
          <small className="text-gray-500">
            ({moment(created_at * 1000).fromNow()})
          </small>
        </span>
      ),
      width: 190,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      render: (updated_at) => (
        <span>
          {moment(new Date(updated_at * 1000)).format("MMM DD, YYYY hh:mm A")}
          <br />
          <small className="text-gray-500">
            ({moment(updated_at * 1000).fromNow()})
          </small>
        </span>
      ),
      width: 190,
    },
  ];

  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        <div className="row gy-5 g-xl-5 mb-5 px-0 mx-0">
          <div className="col-xxl-12 px-0 mx-0">
            <div
              className="card"
              style={{ background: " #e9f1fa", border: "1px dashed #3699ff69" }}
            >
              <div className="card-header border-0 d-flex align-items-center p-5">
                <div>
                  <div className="d-flex flex-wrap gap-4 align-items-center">
                    <div className="position-relative ">
                      <Select
                        className="w-250px"
                        size="large"
                        placeholder="Event Process Type"
                        allowClear
                        value={all_filters?.event_process_type || null}
                        onChange={(e) => {
                          const apiObj = {
                            ...all_filters,
                            event_process_type: e,
                            page: 1,
                          };
                          set_all_filters(apiObj);
                          GetDataSchedulerLogList(apiObj);
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        options={Object.entries(
                          event_list?.event_process_type || {}
                        )?.map((d) => {
                          return {
                            label: d?.[0],
                            value: d?.[0],
                          };
                        })}
                      />
                    </div>
                    <div className="position-relative ">
                      <Select
                        className="w-250px"
                        size="large"
                        placeholder="Event Type"
                        allowClear
                        value={all_filters?.event_type || null}
                        onChange={(e) => {
                          const apiObj = {
                            ...all_filters,
                            event_type: e,
                            page: 1,
                          };
                          set_all_filters(apiObj);
                          GetDataSchedulerLogList(apiObj);
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        options={Object.entries(
                          event_list?.event_type || {}
                        )?.map((d) => {
                          return {
                            label: d?.[0],
                            value: d?.[0],
                          };
                        })}
                      />
                    </div>
                    <div className="position-relative">
                      <Select
                        className="w-250px"
                        size="large"
                        placeholder="Event Name"
                        allowClear
                        value={all_filters?.event_name || null}
                        onChange={(e) => {
                          const apiObj = {
                            ...all_filters,
                            event_name: e,
                            page: 1,
                          };
                          set_all_filters(apiObj);
                          GetDataSchedulerLogList(apiObj);
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        options={Object.entries(
                          event_list?.event_name || {}
                        )?.map((d) => {
                          return {
                            label: d?.[0],
                            value: d?.[0],
                          };
                        })}
                      />
                    </div>
                    <div className="position-relative"></div>
                    {/* <div className="position-relative ">
                      <Select
                        className="w-250px"
                        size="large"
                        placeholder="Select Marketplace"
                        options={GetMarketplaceList(marketplace_list)}
                        value={selected_marketplace}
                        onChange={(e) => {
                          set_selected_marketplace(e);
                          set_selected_currency_sign(currency_list[e]);
                          const apiObj = {
                            ...all_filters,
                          };
                          set_all_filters(apiObj);
                        }}
                      />
                    </div> */}
                    {/* <div className="position-relative ">
                      <button
                        onClick={() => {
                          const apiObj = {
                            ...all_filters,
                            page: 1,
                            "per-page": DefaultPerPage,
                          };
                          setLoading(true);
                          GetDataSchedulerLogList(apiObj);
                        }}
                        className="btn btn-primary btn-sm"
                      >
                        <i class="ki-outline ki-magnifier fs-2"></i>
                       
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0">
                <div className="d-flex flex-stack flex-wrap gap-4">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bold fs-3 mb-1">
                      Data Scheduler
                    </span>
                  </h3>
                </div>
                <div className="card-toolbar">
                  <div className="posion-relative">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        setIsOpenUdpateModal(true);
                      }}
                      disabled={selectedRows?.length === 0}
                    >
                      Update Data Scheduler
                    </button>
                  </div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body py-2">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {loading ? (
                    <NoDataTable columns={columns} type="loading" />
                  ) : list?.length > 0 ? (
                    <EsfTable
                      columns={columns?.map((d) => ({
                        ...d,
                      }))}
                      sticky={{
                        offsetHeader: "10px",
                      }}
                      scroll={{
                        x: "max-content",
                        y: "calc(100vh - 320px)",
                      }}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                    />
                  ) : (
                    <>
                      <NoDataTable columns={columns} type="no-data" />
                    </>
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={all_filters?.["per-page"]}
                  page={all_filters?.page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>
      {isOpenUdpateModal && (
        <UpdateSchedulerModal
          show={isOpenUdpateModal}
          onHide={() => {
            setIsOpenUdpateModal(false);
          }}
          user_list={user_list}
          selected_rows={selectedRows}
        />
      )}
    </>
  );
};

export default AdminDataSchedulerLog;
