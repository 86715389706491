import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Switch,
  Select,
  Table,
  Drawer,
  Collapse,
  Avatar,
  Typography,
  Popover,
  Flex,
  Tag,
  Space,
} from "antd";
import RangePickerESF from "../../../../component/range-picker";
import { Link } from "react-router-dom";
import PerformanceChart from "./lib/performance-chart";
import moment from "moment";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Wrapper } from "./style";

const { Title, Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

function Dashboard() {
  const [open, setOpen] = useState(false);

  const [isRowVisible, setIsRowVisible] = useState(true);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleSwitchChange = (checked) => {
    console.log(`switch to ${checked}`);
    setIsRowVisible(checked);
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  // Define columns for the table
  const columns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (text) => (
        <img src={text} alt="Product" style={{ width: 50, height: 50 }} />
      ), // Renders image
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
    },
    {
      title: "Est. Profit",
      dataIndex: "estProfit",
      key: "estProfit",
    },
    {
      title: "Units Sold",
      dataIndex: "unitsSold",
      key: "unitsSold",
    },
    {
      title: "PPC Units Sold",
      dataIndex: "ppcUnitsSold",
      key: "ppcUnitsSold",
    },
    {
      title: "Organic Units Sold",
      dataIndex: "organicUnitsSold",
      key: "organicUnitsSold",
    },
    {
      title: "Organic Sales",
      dataIndex: "organicSales",
      key: "organicSales",
    },
    {
      title: "PPC Sales",
      dataIndex: "ppcSales",
      key: "ppcSales",
    },
    {
      title: "Ads Spent",
      dataIndex: "adsSpent",
      key: "adsSpent",
    },
    {
      title: "Amazon Fees",
      dataIndex: "amazonFees",
      key: "amazonFees",
    },
    {
      title: "COGS",
      dataIndex: "cogs",
      key: "cogs",
    },
    {
      title: "General Costs",
      dataIndex: "generalCosts",
      key: "generalCosts",
    },
    {
      title: "Refund Cost",
      dataIndex: "refundCost",
      key: "refundCost",
    },
    {
      title: "Refund Unit",
      dataIndex: "refundUnit",
      key: "refundUnit",
    },
  ];

  const data = [
    {
      key: "1",
      product: "https://m.media-amazon.com/images/I/41X75DlvJnL._SL75_.jpg",
      asin: "B0CFG2K7YY",
      sku: "A1-TRPD-US",
      sales: 4926.02,
      estProfit: 1546.87,
      unitsSold: 55,
      ppcUnitsSold: 26,
      organicUnitsSold: 29,
      organicSales: 2627.68,
      ppcSales: 2298.34,
      adsSpent: -841.37,
      amazonFees: -1418.04,
      cogs: 0.0,
      generalCosts: 0.0,
      refundCost: -830.47,
      refundUnit: 12,
    },
    {
      key: "2",
      product: "https://m.media-amazon.com/images/I/41X75DlvJnL._SL75_.jpg",
      asin: "B0838L98WD",
      sku: "G1-73IV-2QM9",
      sales: 1432.6,
      estProfit: 72.28,
      unitsSold: 42,
      ppcUnitsSold: 42,
      organicUnitsSold: 0,
      organicSales: -1087.9,
      ppcSales: 2520.5,
      adsSpent: -762.11,
      amazonFees: -509.34,
      cogs: 0.0,
      generalCosts: 0.0,
      refundCost: -47.44,
      refundUnit: 1,
    },
  ];

  // Sample data
  const products = [
    {
      id: "1",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      sku: "SKU00001",
      image: "https://via.placeholder.com/40", // replace with actual image URLs
    },
    {
      id: "2",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      sku: "SKU00002",
      image: "https://via.placeholder.com/40",
    },
    {
      id: "3",
      name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      sku: "SKU00003",
      image: "https://via.placeholder.com/40",
    },
  ];

  const content = (
    <>
      <RangePickerESF />
    </>
  );

  return (
    <>
      {/*  */}
      <Wrapper
        className="container-fluid fadeInLeftAllPage "
        id="kt_content_container"
      >
        <Row gutter={[16, 16]} className="">
          <Col span={24}>
            <Card
              title="Overview"
              extra={
                <div>
                  Compare{" "}
                  <Switch
                    className="ms-3"
                    defaultChecked
                    onChange={handleSwitchChange}
                  />
                </div>
              }
            >
              {isRowVisible ? (
                <Row className="" gutter={16}>
                  <Col span={6} className="">
                    <Card
                      title="Today"
                      extra={
                        <div className="position-relative">
                          <Popover
                            placement="bottom"
                            content={content}
                            trigger="hover"
                          >
                            <Button className="btn btn-icon d-flex w-auto ">
                              <i className="ki-outline ki-calendar-add fs-1 text-dark"></i>
                            </Button>
                          </Popover>
                        </div>
                      }
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Sales</p>
                          <h4>$96.00</h4>
                        </Col>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Units Sold</p>
                          <h4>01</h4>
                        </Col>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Costs</p>
                          <h4>$0.00</h4>
                        </Col>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Est. Profit</p>
                          <h4>$48.92</h4>
                        </Col>
                      </Row>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        size="large"
                        className="mt-4 fs-5 d-flex justify-content-center"
                        block
                      >
                        Breakdown
                        <i className="ki-outline ki-arrow-right fs-1 text-light"></i>
                      </Button>
                    </Card>
                  </Col>
                  <Col span={6} className="">
                    <Card
                      title="Yesterday"
                      extra={
                        <Link to="" className="d-flex">
                          <i className="ki-outline ki-calendar-add fs-1 text-dark"></i>
                        </Link>
                      }
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Sales</p>
                          <h4>$1,209.10</h4>
                        </Col>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Units Sold</p>
                          <h4>10</h4>
                        </Col>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Costs</p>
                          <h4>$0.00</h4>
                        </Col>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Est. Profit</p>
                          <h4>$553.14</h4>
                        </Col>
                      </Row>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        size="large"
                        className="mt-4 fs-5 d-flex justify-content-center"
                        block
                      >
                        Breakdown{" "}
                        <i className="ki-outline ki-arrow-right fs-1 text-light"></i>
                      </Button>
                    </Card>
                  </Col>
                  <Col span={6} className="">
                    <Card
                      title="Last 7 days"
                      extra={
                        <Link to="" className="d-flex">
                          <i className="ki-outline ki-calendar-add fs-1 text-dark"></i>
                        </Link>
                      }
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Sales</p>
                          <h4>$6,637.40</h4>
                        </Col>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Units Sold</p>
                          <h4>77</h4>
                        </Col>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Costs</p>
                          <h4>$0.00</h4>
                        </Col>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Est. Profit</p>
                          <h4>$2,386.43</h4>
                        </Col>
                      </Row>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        size="large"
                        className="mt-4 fs-5 d-flex justify-content-center"
                        block
                      >
                        Breakdown{" "}
                        <i className="ki-outline ki-arrow-right fs-1 text-light"></i>
                      </Button>
                    </Card>
                  </Col>
                  <Col span={6} className="">
                    <Card
                      title="Last 30 days"
                      extra={
                        <Link to="" className="d-flex">
                          <i className="ki-outline ki-calendar-add fs-1 text-dark"></i>
                        </Link>
                      }
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Sales</p>
                          <h4>$28,891.46</h4>
                        </Col>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Units Sold</p>
                          <h4>355</h4>
                        </Col>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Costs</p>
                          <h4>$0.00</h4>
                        </Col>
                        <Col span={12}>
                          <p className="mb-1 text-gray-600">Est. Profit</p>
                          <h4>$8,042.29</h4>
                        </Col>
                      </Row>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        size="large"
                        className="mt-4 fs-5 d-flex justify-content-center"
                        block
                      >
                        Breakdown{" "}
                        <i className="ki-outline ki-arrow-right fs-1 text-light"></i>
                      </Button>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <Row className="" gutter={16}>
                  <Col span={6} className="">
                    <Card
                      title="Today"
                      extra={
                        <div className="position-relative">
                          <Popover
                            placement="bottom"
                            content={content}
                            trigger="hover"
                          >
                            <Button className="btn btn-icon d-flex w-auto ">
                              <i className="ki-outline ki-calendar-add fs-1 text-dark"></i>
                            </Button>
                          </Popover>
                        </div>
                      }
                    >
                      {/* <Flex className='bdr' justify='space-between'>
                              <Flex>s</Flex>
                              <Flex>s</Flex>
                            </Flex> */}
                      <Row gutter={[16, 16]}>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Sales{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="red"
                            >
                              -100% <Icon icon="tabler:arrow-down-right"></Icon>
                            </Tag>
                          </p>
                          <h4>$0.00</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Sales</p>
                          <h4>$1,209.10</h4>
                        </Col>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Units Sold{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="red"
                            >
                              -100% <Icon icon="tabler:arrow-down-right"></Icon>
                            </Tag>
                          </p>
                          <h4>0</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Units Sold</p>
                          <h4>10</h4>
                        </Col>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Costs{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="green"
                            >
                              0% <Icon icon="tabler:arrow-up-right"></Icon>
                            </Tag>
                          </p>
                          <h4>$0.00</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Costs</p>
                          <h4>$0.00</h4>
                        </Col>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Est. Profit{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="red"
                            >
                              -91.16%{" "}
                              <Icon icon="tabler:arrow-down-right"></Icon>
                            </Tag>
                          </p>
                          <h4>$48.92</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Est. Profit</p>
                          <h4>$553.14</h4>
                        </Col>
                      </Row>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        size="large"
                        className="mt-4 fs-5 d-flex justify-content-center"
                        block
                      >
                        Breakdown{" "}
                        <i className="ki-outline ki-arrow-right fs-1 text-light"></i>
                      </Button>
                    </Card>
                  </Col>
                  <Col span={6} className="">
                    <Card
                      title="Yesterday"
                      extra={
                        <Link to="" className="d-flex">
                          <i className="ki-outline ki-calendar-add fs-1 text-dark"></i>
                        </Link>
                      }
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Sales{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="red"
                            >
                              -100% <Icon icon="tabler:arrow-down-right"></Icon>
                            </Tag>
                          </p>
                          <h4>$1,209.10</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Sales</p>
                          <h4>$733.90</h4>
                        </Col>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Units Sold{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="red"
                            >
                              -100% <Icon icon="tabler:arrow-down-right"></Icon>
                            </Tag>
                          </p>
                          <h4>10</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Units Sold</p>
                          <h4>10</h4>
                        </Col>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Costs{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="green"
                            >
                              0% <Icon icon="tabler:arrow-up-right"></Icon>
                            </Tag>
                          </p>
                          <h4>$0.00</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Costs</p>
                          <h4>$0.00</h4>
                        </Col>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Est. Profit{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="red"
                            >
                              -91.16%{" "}
                              <Icon icon="tabler:arrow-down-right"></Icon>
                            </Tag>
                          </p>
                          <h4>$553.14</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Est. Profit</p>
                          <h4>$261.90</h4>
                        </Col>
                      </Row>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        size="large"
                        className="mt-4 fs-5 d-flex justify-content-center"
                        block
                      >
                        Breakdown{" "}
                        <i className="ki-outline ki-arrow-right fs-1 text-light"></i>
                      </Button>
                    </Card>
                  </Col>
                  <Col span={6} className="">
                    <Card
                      title="Last 7 days"
                      extra={
                        <Link to="" className="d-flex">
                          <i className="ki-outline ki-calendar-add fs-1 text-dark"></i>
                        </Link>
                      }
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Sales{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="red"
                            >
                              -100% <Icon icon="tabler:arrow-down-right"></Icon>
                            </Tag>
                          </p>
                          <h4>$6,637.40</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Sales</p>
                          <h4>$9,032.56</h4>
                        </Col>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Units Sold{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="red"
                            >
                              -100% <Icon icon="tabler:arrow-down-right"></Icon>
                            </Tag>
                          </p>
                          <h4>77</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Units Sold</p>
                          <h4>109</h4>
                        </Col>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Costs{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="green"
                            >
                              0% <Icon icon="tabler:arrow-up-right"></Icon>
                            </Tag>
                          </p>
                          <h4>$0.00</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Costs</p>
                          <h4>$0.00</h4>
                        </Col>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Est. Profit{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="red"
                            >
                              -91.16%{" "}
                              <Icon icon="tabler:arrow-down-right"></Icon>
                            </Tag>
                          </p>
                          <h4>$2,386.43</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Est. Profit</p>
                          <h4>$2,538.87</h4>
                        </Col>
                      </Row>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        size="large"
                        className="mt-4 fs-5 d-flex justify-content-center"
                        block
                      >
                        Breakdown{" "}
                        <i className="ki-outline ki-arrow-right fs-1 text-light"></i>
                      </Button>
                    </Card>
                  </Col>
                  <Col span={6} className="">
                    <Card
                      title="Last 30 days"
                      extra={
                        <Link to="" className="d-flex">
                          <i className="ki-outline ki-calendar-add fs-1 text-dark"></i>
                        </Link>
                      }
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Sales{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="red"
                            >
                              -100% <Icon icon="tabler:arrow-down-right"></Icon>
                            </Tag>
                          </p>
                          <h4>$28,891.46</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Sales</p>
                          <h4>$19,670.01</h4>
                        </Col>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Units Sold{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="red"
                            >
                              -100% <Icon icon="tabler:arrow-down-right"></Icon>
                            </Tag>
                          </p>
                          <h4>355</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Units Sold</p>
                          <h4>310</h4>
                        </Col>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Costs{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="green"
                            >
                              0% <Icon icon="tabler:arrow-up-right"></Icon>
                            </Tag>
                          </p>
                          <h4>$0.00</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Costs</p>
                          <h4>$0.00</h4>
                        </Col>
                        <Col span={14}>
                          <p className="mb-1 text-gray-600">
                            Est. Profit{" "}
                            <Tag
                              className="fs-7 py-0 bg-white border-0"
                              color="red"
                            >
                              -91.16%{" "}
                              <Icon icon="tabler:arrow-down-right"></Icon>
                            </Tag>
                          </p>
                          <h4>$8,042.29</h4>
                        </Col>
                        <Col span={10}>
                          <p className="mb-1 text-gray-600">Est. Profit</p>
                          <h4>$5,604.75</h4>
                        </Col>
                      </Row>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        size="large"
                        className="mt-4 fs-5 d-flex justify-content-center"
                        block
                      >
                        Breakdown{" "}
                        <i className="ki-outline ki-arrow-right fs-1 text-light"></i>
                      </Button>
                    </Card>
                  </Col>
                </Row>
              )}
            </Card>
          </Col>

          <Col span={24}>
            <PerformanceChart />
          </Col>

          <Col span={24}>
            <Card
              title="Top products"
              extra={
                <Space>
                  {/* <Select className='bdr' {...sharedProps} options={Options}/> */}
                  <Select
                    showSearch
                    placeholder="Custom KPI"
                    optionFilterProp="label"
                    variant="filled"
                    size="large"
                    onChange={onChange}
                    onSearch={onSearch}
                    defaultValue={["ppc sales", "products", "unit sold"]}
                    className="min-w-200px"
                    mode="multiple"
                    maxTagCount="responsive"
                    options={[
                      {
                        value: "ppc sales",
                        label: "PPC Sales",
                      },
                      {
                        value: "products",
                        label: "Products",
                      },
                      {
                        value: "unit sold",
                        label: "Unit Sold",
                      },
                    ]}
                  />
                  <Select
                    placeholder="Select a product"
                    style={{ width: "100%" }}
                    variant="filled"
                    size="large"
                    optionLabelProp="label"
                    className="min-w-200px"
                    mode="multiple"
                    maxTagCount="responsive"
                  >
                    {products.map((product) => (
                      <Option
                        key={product.id}
                        value={product.id}
                        label={product.name}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={product.image}
                            size={40}
                            style={{ marginRight: 8 }}
                          />
                          <div>
                            <Text className="text-one" strong>
                              {product.name}
                            </Text>
                            <Text type="secondary">SKU: {product.sku}</Text>
                          </div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                  <RangePickerESF variant="filled" className="w-225px" />
                </Space>
              }
            >
              <Table
                columns={columns}
                dataSource={data}
                scroll={{
                  x: "max-content",
                }}
              />
            </Card>
          </Col>
        </Row>
      </Wrapper>

      <Drawer width="450px" title="Breakdown" onClose={onClose} open={open}>
        <div className=" breakdown-drawer">
          <Row>
            <Col span={12}>
              <Title level={4}>Start Date: 2024-11-07</Title>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <Title level={4}>End Date: 2024-11-07</Title>
            </Col>
          </Row>

          <Collapse defaultActiveKey={[]}>
            <Panel
              header="Sales"
              extra={<span className="fw-bolder">$319.60</span>}
              key="1"
            >
              <Row className="sub">
                <Col span={12}>
                  <Text>Organic</Text>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Text>$319.60</Text>
                </Col>
              </Row>
            </Panel>
            <Panel
              header="Units Sold"
              extra={<span className="fw-bolder">3</span>}
              key="2"
            >
              <Row className="sub">
                <Col span={12}>
                  <Text>Organic unit</Text>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Text>3</Text>
                </Col>
              </Row>
            </Panel>
            <Panel
              header="FBA Fees"
              extra={<span className="fw-bolder">-$113.53</span>}
              key="3"
            >
              <Row className="sub mb-2">
                <Col span={16}>
                  <Text>Fba Per Unit Fulfillment Fee</Text>
                </Col>
                <Col span={8} style={{ textAlign: "right" }}>
                  <Text>-$65.56</Text>
                </Col>
              </Row>
              <Row className="sub">
                <Col span={12}>
                  <Text>Commission</Text>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Text>-$47.97</Text>
                </Col>
              </Row>
            </Panel>
            <Panel
              header="PPC Costs"
              extra={<span className="fw-bolder">-$1.19</span>}
              key="4"
            >
              <Row className="sub mb-0">
                <Col span={16}>
                  <Text>Sponsored Brands Video PPC</Text>
                </Col>
                <Col span={8} style={{ textAlign: "right" }}>
                  <Text>-$65.56</Text>
                </Col>
              </Row>
            </Panel>

            <Panel
              showArrow={false}
              collapsible="icon"
              className="main-item mt-3"
              header="Total Profit"
              extra={<span className="fw-bolder">$204.98</span>}
              key="5"
            ></Panel>
            {/* <div className='separator separator-dashed my-2'></div> */}
            <Panel
              showArrow={false}
              collapsible="icon"
              className="main-item"
              header="Profit Margin"
              extra={<span className="fw-bolder">64.12%</span>}
              key="6"
            ></Panel>
          </Collapse>
        </div>
      </Drawer>
    </>
  );
}

export default Dashboard;
