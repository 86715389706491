import { Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MakeApiCall } from "../../../../apis/make-api-call";
const marketplaceObj = {
  A2Q3Y263D00KWC: "Amazon.com.br",
  A2EUQ1WTGCTBG2: "Amazon.ca",
  A1AM78C64UM0Y8: "Amazon.com.mx",
  ATVPDKIKX0DER: "Amazon.com",
  A2VIGQ35RCS4UG: "Amazon.ae",
  A1PA6795UKMFR9: "Amazon.de",
  A1RKKUPIHCS9HS: "Amazon.es",
  A13V1IB3VIYZZH: "Amazon.fr",
  A1F83G8C2ARO7P: "Amazon.uk",
  A21TJRUUN4KGV: "Amazon.in",
  APJ6JRA9NG5V4: "Amazon.it",
  A1805IZSGTT6HS: "Amazon.nl",
  A17E79C6D8DWNP: "Amazon.sa",
  A33AVAJ2PDY3EV: "Amazon.tr",
  A19VAU5U5O7RUS: "Amazon.sg",
  A1VC38T7YXB528: "Amazon.jp",
  AAHKV2X7AFYLW: "Amazon.cn",
};
const numberWithCommas = (x) => {
  if (x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return x;
  }
  return x;
};

export default function (props) {
  const { show, onHide, data, advertisingList, getList } = props;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    if (advertisingList?.length > 0) {
      const selectedRowKeys_ = [];
      advertisingList?.forEach((e) => {
        const index = data.findIndex((d) => d.profileId === e.profile_id);
        if (index !== -1) {
          selectedRowKeys_.push(data[index]);
        }
      });
      setSelectedRowKeys(selectedRowKeys_);
    }
  }, [advertisingList]);

  const onSelectChange = (_, newSelectedRow) => {
    setSelectedRowKeys(newSelectedRow);
  };
  const rowSelection = {
    selectedRowKeys: selectedRowKeys?.map((d) => d.key),
    onChange: onSelectChange,
  };

  const SaveADCredentials = async (data) => {
    const response = await MakeApiCall(
      `user-credential/save-ad-profile`,
      "post",
      data,
      true
    );
    if (response?.status) {
      message.destroy();
      message.success(response?.message);
      getList("Advertising-API");
      onHide();
    } else {
      message.destroy();
      message.error(response?.message);
    }
  };

  const submit = (value) => {
    message.destroy();
    message.loading("Loading...", 0);
    SaveADCredentials(value);
  };

  const columns = [
    {
      title: "Ad Profile Id",
      dataIndex: "profileId",
    },
    {
      title: "Country Code",
      dataIndex: "countryCode",
    },
    {
      title: "Seller Name",
      render: (text) => {
        return <span>{text?.accountInfo?.name || "-"}</span>;
      },
    },
    {
      title: "Brand Entity Id",
      render: (text) => {
        return <span>{text?.accountInfo?.brandEntityId || "-"}</span>;
      },
    },
    {
      title: "brand Name",
      render: (text) => {
        return <span>{text?.accountInfo?.brandName || "-"}</span>;
      },
    },
    {
      title: "Daily Budget",
      render: (text) => {
        return <span>{numberWithCommas(text?.dailyBudget || 0)}</span>;
      },
    },
    {
      title: "Time Zone",
      dataIndex: "timezone",
    },
    {
      title: "Currency Code",
      dataIndex: "currencyCode",
    },
    {
      title: "Marketplace",
      render: (text) => {
        return (
          <span>
            {marketplaceObj[text?.accountInfo?.marketplaceStringId] || "-"}
          </span>
        );
      },
    },

    {
      title: "Marketplace Id",
      render: (text) => {
        return <span>{text?.accountInfo?.marketplaceStringId || "-"}</span>;
      },
    },
  ];

  return (
    <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Advertising Profile Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="search_column_new">
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            pagination={true}
            scroll={{ x: "max-content" }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          disabled={selectedRowKeys?.length === 0}
          onClick={() => {
            if (selectedRowKeys?.length !== 0) {
              message.destroy();
              message.loading("Loading...", 0);
              submit({
                ad_profile_ids: selectedRowKeys?.map((d) => d?.profileId),
              });
            }
          }}
          type="button"
          className="btn btn-primary px-7"
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
