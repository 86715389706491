import React from 'react'
import { Modal } from 'antd'


function AddProduct({ modal1Open, setModal1Open }) {
  return (
    <Modal title="LQS Score" centered open={modal1Open} onCancel={() => setModal1Open(false)} width={500}>
        sad
    </Modal>
  )
}

export default AddProduct