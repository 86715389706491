import { lazy } from "react";
import Dashboard from "../modules/pages/dashboard/dashboard";
// import Dashboard from "../modules/pages/dashboard/dashboard";
export default [
  {
    path: "/advertisement-dashboard",
    // component: <div>TEST</div>,
    component: <Dashboard />,
    exact: true,
  },
];
